import React from 'react';

import Layout from '../components/logic/layouts/Layout';
import Section from '../components/logic/layouts/Section';
import SEO from '../components/seo';

import StyledH2 from '../components/styled/elements/StyledH2';
import StyledH3 from '../components/styled/elements/StyledH3';
import StyledParagraph from '../components/styled/elements/StyledParagraph';
import StyledUl from '../components/styled/elements/StyledUl';
import StyledLi from '../components/styled/elements/StyledLi';

import iconomicInfo from '../constants/iconomicInfo';

const PersonalPolicyPage = () => (
  <Layout>
    <SEO title="Personuppgiftspolicy" />
    <Section medium marginTop="none">
      <StyledH2 textAlign="center">Personuppgiftspolicy - Iconomic</StyledH2>
      <StyledParagraph textAlign="center">Information enligt dataskyddsförordningen (General Data Protection Regulation, GDPR) Gällande från 25 maj 2018</StyledParagraph>
      <StyledH3 fontWeight="bold">1. Personuppgiftsansvarig</StyledH3>
      <StyledParagraph>Iconomic är en del av {iconomicInfo.COMPANY_NAME}, org.nr 559000-0237 (“moank”), vilken är personuppgiftsansvarig för behandlingen av dina personuppgifter i anslutning till vår verksamhet. Vi behandlar alltid den information som du anförtror oss på ett varsamt och ansvarsfullt sätt. Denna personuppgiftspolicy ger dig detaljerad information om hur vi samlar in och använder dina personuppgifter. Den beskriver också vilka rättigheter du har när det gäller dina personuppgifter. Vid förändringar om hur vi behandlar dina personuppgifter kommer vi att uppdatera denna personuppgiftspolicy. Om du har några frågor är du alltid välkommen att kontakta vår kundtjänst på telefon {iconomicInfo.PHONE} eller via e-post till {iconomicInfo.EMAIL}.</StyledParagraph>
      <StyledH3 fontWeight="bold">2. Insamling av personuppgifter</StyledH3>
      <StyledParagraph>Moank samlar in och använder dina personuppgifter i den mån det är nödvändigt inom ramen för verksamheten och för att uppnå en hög standard på våra skräddarsydda produkter och tjänster.</StyledParagraph>
      <StyledParagraph>Vi samlar in olika typer av personuppgifter om dig, det kan bland annat vara:</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light">ID-information (t.ex. namn, personnummer, IP-adress och BankID).</StyledLi>
        <StyledLi fontWeight="light">Kontaktinformation (t.ex. postadress och e-postadress, telefonnummer).</StyledLi>
        <StyledLi fontWeight="light">Familjesituation (t.ex. civilstånd, antal barn).</StyledLi>
        <StyledLi fontWeight="light">Skattemässig ställning (t.ex. skatte-ID, skattestatus).</StyledLi>
        <StyledLi fontWeight="light">Information om utbildning och anställning (t.ex. utbildningsnivå, anställning, arbetsgivarens namn, ersättning).</StyledLi>
        <StyledLi fontWeight="light">Bankuppgifter, finansiell information samt transaktionshistorik (t.ex. uppgifter om bankkonton, kreditkortsnummer, penningöverföringar, transaktioner, tillgångar, kredithistoria, skulder och utgifter).</StyledLi>
      </StyledUl>
      <StyledParagraph>Uppgifter om dina vanor och preferenser:</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light">Hur du använder vår webbplats och ”Mina Sidor” (din personliga inloggningssida på Moanks webbplats).</StyledLi>
        <StyledLi fontWeight="light">Vilken webbläsare och teknisk utrustning du använder.</StyledLi>
        <StyledLi fontWeight="light">Hur du använder våra produkter och tjänster (t.ex. Betalningshistorik och uttag).</StyledLi>
        <StyledLi fontWeight="light">Kommunikation med oss (e-postmeddelanden, chattar och telefonsamtal).</StyledLi>
      </StyledUl>
      <StyledParagraph>Vi efterfrågar aldrig personuppgifter avseende din rasmässiga eller etniska bakgrund, politiska åsikter, religiösa eller filosofiska uppfattningar, medlemskap i en fackförening, genetiska uppgifter eller uppgifter om ditt sexliv och din sexuella läggning.</StyledParagraph>
      <StyledParagraph>De uppgifter vi använder om dig kan antingen tillhandahållas direkt av dig eller inhämtas från följande källor:</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light">Publikationer/databaser som har gjorts tillgängliga av myndigheterna.</StyledLi>
        <StyledLi fontWeight="light">Våra tjänsteleverantörer och kommersiella samarbetspartners.</StyledLi>
        <StyledLi fontWeight="light">Tredje part såsom kreditupplysningsföretag och banker, kreditmarknadsbolag samt betalningsinstitut.</StyledLi>
        <StyledLi fontWeight="light">Kakor (cookies).</StyledLi>
      </StyledUl>
      <StyledH3 fontWeight="bold">3. Varför och på vilka grunder behandlar vi personuppgifter</StyledH3>
      <StyledParagraph>Moank behandlar enbart dina personuppgifter för vissa specifika ändamål och när vi har en rättslig grund för att göra det.</StyledParagraph>
      <StyledParagraph>För att ingå och efterleva våra avtal med dig, innebärande att:</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light">Kontrollera och registrera de uppgifter som krävs för att ingå avtal med dig.</StyledLi>
        <StyledLi fontWeight="light">Dokumentera, administrera och fullgöra sådana avtal.</StyledLi>
        <StyledLi fontWeight="light">Ge dig information om våra produkter och tjänster.</StyledLi>
        <StyledLi fontWeight="light">Bistå dig och svara på frågor.</StyledLi>
        <StyledLi fontWeight="light">Utvärdera om vi kan erbjuda dig en produkt eller tjänst och på vilka villkor.</StyledLi>
      </StyledUl>
      <StyledParagraph>För att fullgöra juridiska och regulatoriska skyldigheter, i form av:</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light">Bank- och finansreglering i enlighet med vilka vi:</StyledLi>
        <StyledUl>
          <StyledLi fontWeight="light">etablerar säkerhetsåtgärder för att förebygga angrepp eller bedrägerier,</StyledLi>
          <StyledLi fontWeight="light">upptäcker transaktioner som avviker från normala mönster,</StyledLi>
          <StyledLi fontWeight="light">definierar din kreditrisk och din återbetalningsförmåga,</StyledLi>
          <StyledLi fontWeight="light">övervakar och rapporterar risker som vi skulle kunna ådra oss, och</StyledLi>
          <StyledLi fontWeight="light">arkiverar, vid behov, chattar och mejl.</StyledLi>
        </StyledUl>
        <StyledLi fontWeight="light">Rapportering till Skatteverket, Polismyndigheten, Kronofogden, Finansinspektionen och andra myndigheter.</StyledLi>
        <StyledLi fontWeight="light">Svarar på en officiell förfrågan från behörig offentlig myndighet eller ett behörigt juridiskt organ.</StyledLi>
        <StyledLi fontWeight="light">Förebyggande av penningtvätt och finansiering av terrorism.</StyledLi>
        <StyledLi fontWeight="light">Efterlevnad av lagstiftning gällande sanktioner och embargon.</StyledLi>
        <StyledLi fontWeight="light">Bekämpande av skattebedrägerier samt uppfyllande av skattekontroller och anmälningsplikter.</StyledLi>
      </StyledUl>
      <StyledParagraph>När vi har ett berättigat intresse, innefattande:</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light">Förebyggande av bedrägerier.</StyledLi>
        <StyledLi fontWeight="light">IT-hantering, inklusive infrastrukturhantering och kontinuitet i verksamheten samt IT-säkerhet.</StyledLi>
        <StyledLi fontWeight="light">Etablering av statistiska modeller, baserade på analyser av transaktioner (t.ex. i syfte att definiera din kreditrisk).</StyledLi>
        <StyledLi fontWeight="light">Upprättande av aggregerad statistik, tester och modeller, för forskning och utveckling, för att förbättra riskhanteringen eller för att förbättra befintliga produkter och tjänster eller skapa nya.</StyledLi>
        <StyledLi fontWeight="light">Skräddarsy erbjudande till dig genom att:</StyledLi>
        <StyledUl>
          <StyledLi fontWeight="light">förbättra kvaliteten på våra produkter och tjänster inom bank, finans och försäkringar</StyledLi>
          <StyledLi fontWeight="light">annonsera om produkter eller tjänster som passar din situation och profil som vi erhåller.</StyledLi>
        </StyledUl>
        <StyledLi fontWeight="light">Detta går att uppnå genom att vi:</StyledLi>
        <StyledUl>
          <StyledLi fontWeight="light">analyserar dina vanor och preferenser inom olika kanaler (mejl eller meddelanden, besök på våra webbplatser, osv.),</StyledLi>
          <StyledLi fontWeight="light">sammanför de produkter och tjänster du redan har eller använder med andra uppgifter som vi har om dig (vi kan t.ex. identifiera att du har en kredit hos oss men ännu ingen låneskyddsförsäkring).</StyledLi>
          <StyledLi fontWeight="light">övervakar transaktioner för att identifiera dem som avviker från de normala rutinerna.</StyledLi>
        </StyledUl>
      </StyledUl>
      <StyledParagraph>När du har gett ditt samtycke, för att:</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light">Fatta automatiserade kreditbeslut baserat på profilering.</StyledLi>
      </StyledUl>
      <StyledH3 fontWeight="bold">4. Profilering och automatiserade beslut</StyledH3>
      <StyledParagraph>Med profilering avses automatisk behandling av personuppgifter som används för att bedöma vissa personliga egenskaper hos dig som person, i synnerhet för att analysera eller förutsäga din ekonomiska situation, personliga preferenser och beteende. Profilering används av oss för marknads- och kundanalyser, systemutveckling, marknadsföring, vid automatiserade beslut (se nedan) och vid transaktionsmonitorering för att motverka bedrägerier samt penningtvätt och finansiering av terrorism. Den rättsliga grunden för profileringen är vårt berättigade intresse och rättsliga förpliktelse. I de flesta fall grundas vårt kreditbeslut enbart på automatiserad behandling som innefattar profilering. De uppgifter som du lämnat i ansökan, uppgifter från externa källor såsom offentliga register och kreditupplysningsföretag samt transaktionshistorik från ditt bankkonto utgör grund för beslutet. Det automatiserade förfarandet utförs eftersom det medför positiva effekter för dig som kund såsom ökad enhetlighet och korrekthet i beslutsfattandet, minskad risk för att du inte ska kunna fullgöra betalningar och snabbare beslutsfattande. I de fall ett kreditbeslut har grundats enbart på automatiserad behandling, har du alltid rätt att få beslutet omprövat av våra handläggare. Den rättsliga grunden för detta automatiserade beslutsfattande är ditt samtycke.</StyledParagraph>
      <StyledH3 fontWeight="bold">5. Vilka kan få tillgång till dina personuppgifter</StyledH3>
      <StyledParagraph>För att kunna uppfylla ovannämnda ändamål kan personuppgifter, inom ramen för gällande regler om sekretess och skydd av personuppgifter, lämnas vidare till:</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light">Kreditupplysningsföretag.</StyledLi>
        <StyledLi fontWeight="light">Tjänsteleverantörer som utför tjänster åt oss (t.ex. inkassoföretag, Bankgirocentralen).</StyledLi>
        <StyledLi fontWeight="light">Finansieringspartner och kommersiella samarbetspartners.</StyledLi>
        <StyledLi fontWeight="light">Finansiella organ, statliga myndigheter eller offentliga organ, på begäran och enbart i den utsträckning som är tillåten enligt lag.</StyledLi>
      </StyledUl>
      <StyledParagraph>Vi behandlar dina personuppgifter enbart inom EU/EES.</StyledParagraph>
      <StyledH3 fontWeight="bold">6. Hur skyddar vi dina personuppgifter</StyledH3>
      <StyledParagraph>Du ska alltid känna dig trygg när du lämnar dina personuppgifter till Moank. Vi har därför vidtagit lämpliga tekniska och organisatoriska åtgärder för att säkerställa skyddet för dina personuppgifter mot oavsiktlig eller olaglig förstöring, förlust eller ändring, obehörigt röjande av eller obehörig åtkomst. Vi ställer även motsvarande krav på våra samarbetspartner.</StyledParagraph>
      <StyledParagraph>Vi strävar alltid efter att inte behandla fler uppgifter än nödvändigt och vi pseudonymiserar dina uppgifter när det är möjligt.</StyledParagraph>
      <StyledH3 fontWeight="bold">7. Hur länge sparas dina personuppgifter</StyledH3>
      <StyledParagraph>Personuppgifter sparas i enlighet med gällande lagstiftning, såsom penningtvätts- och bokföringslagstiftning (5 år respektive 7 år), och i övrigt enbart den tidsperiod som är nödvändigt med avseende på de specifika ändamålen för behandlingen. Personuppgifter som har betydelse för det avtalsrättsliga förhållandet mellan dig och Moank sparas normalt så länge avtalsförhållandet består och därefter i högst 10 år på grund av regler om preskription. Om du inte ingår avtal med Moank, sparas normalt personuppgifterna som längst 3 månader, men vissa uppgifter kan komma att sparas längre på grund av exempelvis penningtvättslagstiftningen.</StyledParagraph>
      <StyledH3 fontWeight="bold">8. Dina rättigheter</StyledH3>
      <StyledParagraph>Enligt dataskyddsförordningen har du nedan listade rättigheter. Om du vill använda dig av någon av dina rättigheter skicka ett brev till {iconomicInfo.COMPANY_NAME}, Box 3652, 103 59 Stockholm, eller e-post till {iconomicInfo.EMAIL}. Bifoga en skannad kopia/papperskopia av ditt ID-kort i identifieringssyfte.</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light"><strong>Tillgång:</strong> du har rätt att erhålla information om hur vi behandlar dina personuppgifter samt en kopia av sådana personuppgifter.</StyledLi>
        <StyledLi fontWeight="light"><strong>Rättelse:</strong> om det visar sig att personuppgifter som vi behandlar om dig är felaktiga har du rätt att begära att uppgifterna rättas. Du kan också begära att en ofullständig uppgift om dig kompletteras.</StyledLi>
        <StyledLi fontWeight="light"><strong>Radering:</strong> du har rätt att få någon av eller alla dina personuppgifter raderade. I vissa fall kan vi inte radera uppgifterna. Det beror i så fall på att uppgifterna fortfarande är nödvändiga för det ursprungliga ändamålet och vi fortfarande har en legal grund för att behandla dem.</StyledLi>
        <StyledLi fontWeight="light"><strong>Begränsning:</strong> du kan kräva en begränsning av behandlingen av dina personuppgifter.</StyledLi>
        <StyledLi fontWeight="light"><strong>Dataportabilitet:</strong> när vi behandlar dina personuppgifter med stöd av avtal eller samtycke har du rätt att få de personuppgifter du har lämnat till oss återlämnade eller, där det är tekniskt möjligt, överförda till en tredje part.</StyledLi>
        <StyledLi fontWeight="light"><strong>Invändning:</strong> du kan invända mot behandlingen av dina personuppgifter på grundval av din specifika situation. Du har en absolut rätt att invända mot bearbetningen av dina personuppgifter för direkta marknadsföringssyften, vilket omfattar profilering avseende sådan direkt marknadsföring.</StyledLi>
        <StyledLi fontWeight="light"><strong>Återkalla samtycke:</strong> där du har gett ditt samtycke för behandling av dina personuppgifter har du rätt att när som helst dra tillbaka samtycket.</StyledLi>
        <StyledLi fontWeight="light"><strong>Lämna klagomål till tillsynsmyndighet:</strong> om du har klagomål på hur vi har behandlat dina personuppgifter kan du vända dig till tillsynsmyndigheten.</StyledLi>
      </StyledUl>
    </Section>
  </Layout>
);

export default PersonalPolicyPage;
